import Vue from "vue";
import VueRouter from "vue-router";
import Admin from "@/store/admin/admin";
import { LoginStorage } from "@/store/login-storage";


Vue.use(VueRouter);

const routes = [
  // ログイン関連
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/Index.vue")
  },
  {
    path: "/forget-password",
    name: "forget-password",
    component: () => import("@/views/forget-password/Index.vue")
  },
  {
    path: "/forget-password-sendmail",
    name: "forget-password-sendmail",
    component: () => import("@/views/forget-password/SendMail.vue")
  },
  {
    path: "/password-reset",
    name: "password-reset",
    component: () => import("@/views/password-reset/Index.vue")
  },
  // ホーム
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/dash-board/Index.vue")
  },
  // アピール（プッシュメッセージ）
  {
    path: "/push-notifications",
    name: "push-notifications",
    component: () => import("@/views/push-notifications/Index.vue")
  },
  {
    path: "/push-notifications/:shopId/remind",
    name: "push-notifications-remind",
    component: () => import("@/views/push-notifications/Remind.vue"),
    props: true
  },
  {
    path: "/push-notifications/:shopId/list",
    name: "push-notifications-list",
    component: () => import("@/views/push-notifications/List.vue"),
    props: true
  },
  {
    path: "/push-notifications/new",
    name: "push-notifications-new",
    component: () => import("@/views/push-notifications/NewEdit.vue")
  },
  {
    path: "/push-notifications/:id/edit",
    name: "push-notifications-edit",
    component: () => import("@/views/push-notifications/NewEdit.vue"),
    props: true
  },
  // アピール（アプリ内通知）
  {
    path: "/app-notification",
    name: "app-notification",
    component: () => import("@/views/app-notification/Index.vue")
  },
  {
    path: "/app-notification/new",
    name: "app-notification-new",
    component: () => import("@/views/app-notification/NewEdit.vue")
  },
  {
    path: "/app-notification/:id/edit",
    name: "app-notification-edit",
    component: () => import("@/views/app-notification/NewEdit.vue"),
    props: true
  },
  {
    path: "/app-notification/:id/list",
    name: "app-notification-list",
    component: () => import("@/views/app-notification/List.vue"),
    props: true
  },
  // アピール（クーポン）
  {
    path: "/coupon",
    name: "coupon",
    component: () => import("@/views/coupon/Index.vue")
  },
  {
    path: "/coupon/:shopId/list",
    name: "coupon-list",
    component: () => import("@/views/coupon/List.vue"),
    props: true
  },
  {
    path: "/coupon/new",
    name: "coupon-new",
    component: () => import("@/views/coupon/NewEdit.vue")
  },
  {
    path: "/coupon/:id/edit",
    name: "coupon-edit",
    component: () => import("@/views/coupon/NewEdit.vue"),
    props: true
  },
  // アピール（ポイント）
  {
    path: "/points",
    name: "points",
    component: () => import("@/views/points/Index.vue")
  },
  {
    path: "/points/:shopId/list",
    name: "points-list",
    component: () => import("@/views/points/List.vue"),
    props: true
  },
  {
    path: "/points/:id/edit",
    name: "points-edit",
    component: () => import("@/views/points/Edit.vue"),
    props: true
  },
  {
    path: "/points/:shopId/setting",
    name: "points-setting",
    component: () => import("@/views/points/Setting.vue"),
    props: true
  },
  // アピール（スタンプ）
  {
    path: "/stamps",
    name: "stamps",
    component: () => import("@/views/stamps/Index.vue")
  },
  {
    path: "/stamps/:shopId/list",
    name: "stamps-list",
    component: () => import("@/views/stamps/List.vue"),
    props: true
  },
  {
    path: "/stamps/new",
    name: "stamps-new",
    component: () => import("@/views/stamps/NewEdit.vue")
  },
  {
    path: "/stamps/:id/edit",
    name: "stamps-edit",
    component: () => import("@/views/stamps/NewEdit.vue"),
    props: true
  },
  {
    path: "/stamps/:id/setting",
    name: "stamps-setting",
    component: () => import("@/views/stamps/Setting.vue"),
    props: true
  },
  {
    path: "/stamps/:id/qrcode",
    name: "stamps-qrcode",
    component: () => import("@/views/stamps/Qrcode.vue"),
    props: true
  },
  // アピール（デジタルチケット）
  {
    path: "/digital-ticket",
    name: "ticket",
    component: () => import("@/views/digital-ticket/Index.vue")
  },
  {
    path: "/digital-ticket/:shopId/list",
    name: "ticket-list",
    component: () => import("@/views/digital-ticket/List.vue"),
    props: true
  },
  {
    path: "/digital-ticket/:shopId/setting",
    name: "ticket-setting",
    component: () => import("@/views/digital-ticket/Setting.vue"),
    props: true
  },
  {
    path: "/ticket/new",
    name: "ticket-new",
    component: () => import("@/views/digital-ticket/NewEdit.vue")
  },
  {
    path: "/ticket/:id/edit",
    name: "ticket-edit",
    component: () => import("@/views/digital-ticket/NewEdit.vue"),
    props: true
  },
  // オーダー&ペイ（次フェーズに見送り）
  // コミュニケーション
  {
    path: "/communication",
    name: "communication",
    component: () => import("@/views/communication/Index.vue")
  },
  {
    path: "/communication/:id/edit",
    name: "communication-edit",
    component: () => import("@/views/communication/Edit.vue"),
    props: true
  },
  {
    path: "/communication/:id/talk",
    name: "communication-talk",
    component: () => import("@/views/communication/Talk.vue"),
    props: true,
    children: [
      {
        path: ":talkId",
        component: () => import("@/views/communication/Comment.vue"),
        props: true
      }
    ]
  },
  {
    path: "/communication/:id/talk/:talkId/mobile",
    name: "communication-talk-comment-sp",
    component: () => import("@/views/communication/Comment.vue"),
    props: true
  },
  // 動画
  {
    path: "/communication/:id/movie/",
    name: "communication-movie",
    component: () => import("@/views/communication/Movie.vue"),
    props: true
  },
  {
    path: "/communication/:id/movie/download",
    name: "communication-movie-download",
    component: () => import("@/views/communication/Movie.vue"),
    props: true
  },
  {
    path: "/communication/:id/movie/delete",
    name: "communication-movie-delete",
    component: () => import("@/views/communication/Movie.vue"),
    props: true
  },
  // カスタマー
  {
    path: "/customer-information",
    name: "customers",
    component: () => import("@/views/customers/Index.vue")
  },
  {
    path: "/customer-information/:id/edit",
    name: "customer-edit",
    component: () => import("@/views/customers/Edit.vue"),
    props: true
  },
  {
    path: "/customer-information-downloads",
    name: "customer-download",
    component: () => import("@/views/customers/Download.vue")
  },
  {
    path: "/customer-information-light-downloads",
    name: "customer-light-download",
    component: () => import("@/views/customers/LightDownload.vue")
  },
  // 店舗管理
  {
    path: "/shop-management",
    name: "shop-manage",
    component: () => import("../views/shop-manage/Index.vue")
  },
  {
    path: "/shop-management/new",
    name: "shop-manage-new",
    component: () => import("../views/shop-manage/NewEdit.vue")
  },
  {
    path: "/shop-management/:id/edit",
    name: "shop-manage-edit",
    component: () => import("../views/shop-manage/NewEdit.vue"),
    props: true
  },
  // メニュー管理
  {
    path: "/menu-management",
    name: "menu-management",
    component: () => import("../views/menu-management/Index.vue")
  },
  {
    path: "/menu-management/:shopId/category",
    name: "menu-management-category",
    component: () => import("../views/menu-management/Category.vue"),
    props: true
  },
  {
    path: "/menu-management/:shopId/category/new",
    name: "menu-management-category-new",
    component: () => import("../views/menu-management/NewEdit.vue"),
    props: true
  },
  {
    path: "/menu-management/:shopId/category/:id/edit",
    name: "menu-management-category-edit",
    component: () => import("../views/menu-management/NewEdit.vue"),
    props: true
  },
  {
    path: "/menu-management/:shopId/category/:id/menu-list",
    name: "menu-management-category-menu-list",
    component: () => import("../views/menu-management/MenuList.vue"),
    props: true
  },
  {
    path: "/menu-management/:shopId/category/:categoryId/menu-list/new",
    name: "menu-management-category-menu-list-new",
    component: () => import("../views/menu-management/NewEditMenu.vue"),
    props: true
  },
  {
    path: "/menu-management/:shopId/category/:categoryId/menu-list/:id/edit",
    name: "menu-management-category-menu-list-edit",
    component: () => import("../views/menu-management/NewEditMenu.vue"),
    props: true
  },
  // 分析（アプリDL）
  {
    path: "/analysis/app-download",
    name: "analysis-app-download",
    component: () => import("@/views/analysis/AppDownload.vue")
  },
  // 分析（ランク（ポイント））
  {
    path: "/analysis/rank-point",
    name: "analysis-rank-point",
    component: () => import("@/views/analysis/RankPoint.vue")
  },
  // 分析（ランク（スタンプ））
  {
    path: "/analysis/rank-stamp",
    name: "analysis-rank-stamp",
    component: () => import("@/views/analysis/RankStamp.vue")
  },
  // 分析（属性統計）
  {
    path: "/analysis/attributes-statistics",
    name: "analysis-attributes-statistics",
    component: () => import("@/views/analysis/AttributesStatistics.vue")
  },
  // 分析（フォロー）
  {
    path: "/analysis/follow",
    name: "analysis-follow",
    component: () => import("@/views/analysis/Follow.vue")
  },
  // 分析（予約）
  {
    path: "/analysis/booking",
    name: "analysis-booking",
    component: () => import("@/views/analysis/Booking.vue")
  },
  // 分析（プッシュ通知）
  {
    path: "/analysis/push-status",
    name: "analysis-push-status",
    component: () => import("@/views/analysis/PushNotification.vue")
  },
  // 分析（クーポン）
  {
    path: "/analysis/coupon",
    name: "analysis-coupon",
    component: () => import("@/views/analysis/Coupon.vue")
  },
  // 分析（クーポン（店舗・クーポン別）
  {
    path: "/analysis/coupon-usage",
    name: "analysis-coupon-usage",
    component: () => import("@/views/analysis/CouponUsage.vue")
  },
  // 分析（スタンプ）
  {
    path: "/analysis/stamp",
    name: "analysis-stamp",
    component: () => import("@/views/analysis/Stamp.vue")
  },
  // 分析（デジタルチケット（利用数））
  {
    path: "/analysis/digitalticket",
    name: "analysis-digitalticket",
    component: () => import("@/views/analysis/DigitalTicket.vue")
  },
  // 分析（デジタルチケット（期間別））
  {
    path: "/analysis/digital-ticket-period",
    name: "analysis-digital-ticket-period",
    component: () => import("@/views/analysis/DigitalTicketPeriod.vue")
  },
  // 分析（デジタルチケット（全体））
  {
    path: "/analysis/digital-ticket-general",
    name: "analysis-digital-ticket-general",
    component: () => import("@/views/analysis/DigitalTicketGeneral.vue")
  },
  // 分析（ポイントカード（UPLINK））
  {
    path: "/analysis/point",
    name: "analysis-point",
    component: () => import("@/views/analysis/Point.vue")
  },
  // 分析（ポイントカード（店舗））
  {
    path: "/analysis/point-original",
    name: "analysis-point-original",
    component: () => import("@/views/analysis/PointOriginal.vue")
  },
  // 分析（来店数）
  {
    path: "/analysis/visit",
    name: "analysis-visit",
    component: () => import("@/views/analysis/Visit.vue")
  },
  // 分析（アイコンタップ数）
  {
    path: "/analysis/icon-tap",
    name: "analysis-icon-tap",
    component: () => import("@/views/analysis/IconTap.vue")
  },
  // アプリデザイン
  {
    path: "/appdesign",
    name: "app-design",
    component: () => import("@/views/app-design/Index.vue")
  },
  {
    path: "/appdesign/:shopId/edit",
    name: "app-design-edit",
    component: () => import("../views/app-design/NewEdit.vue"),
    props: true
  },
  // 予約
  {
    path: "/booking",
    name: "booking",
    component: () => import("@/views/booking/Index.vue")
  },
  {
    path: "/booking/:shopId/confirm",
    name: "booking-confirm",
    component: () => import("@/views/booking/Confirm.vue"),
    props: true
  },
  {
    path: "/booking/:shopId/edit",
    name: "booking-edit",
    component: () => import("@/views/booking/Edit.vue"),
    props: true
  },
  {
    path: "/booking/:shopId/confirm/:id/edit",
    name: "booking-confirm-edit",
    component: () => import("@/views/booking/ConfirmEdit.vue"),
    props: true
  },
  // マスターデータ管理（支店カテゴリ設定）
  {
    path: "/category",
    name: "category",
    component: () => import("@/views/category/Index.vue")
  },
  {
    path: "/category/:id/edit",
    name: "category-edit",
    component: () => import("@/views/category/Edit.vue"),
    props: true
  },
  // マスターデータ管理（ユーザプロフィール項目）
  {
    path: "/user-profile",
    name: "user-profile",
    component: () => import("@/views/user-profile/Index.vue")
  },
  {
    path: "/user-profile/:shopId/edit",
    name: "user-profile-edit",
    component: () => import("@/views/user-profile/Edit.vue"),
    props: true
  },
  // 設定（店舗アカウント設定）
  {
    path: "/login-members-setting",
    name: "login-members",
    component: () => import("@/views/login-members/Index.vue")
  },
  {
    path: "/login-members-setting/:id/authority-setting",
    name: "authority-setting",
    component: () => import("@/views/login-members/AuthoritySetting.vue"),
    props: true
  },
  {
    path: "/login-members-setting/new",
    name: "login-members-new",
    component: () => import("@/views/login-members/NewEdit.vue")
  },
  {
    path: "/login-members-setting/:id/edit",
    name: "login-members-edit",
    component: () => import("@/views/login-members/NewEdit.vue"),
    props: true
  },
  // 設定（権限ロール）
  {
    path: "/authority",
    name: "authority",
    component: () => import("@/views/authority/Index.vue")
  },
  {
    path: "/authority/new",
    name: "authority-new",
    component: () => import("@/views/authority/NewEdit.vue"),
    props: true
  },
  {
    path: "/authority/:id/edit",
    name: "authority-edit",
    component: () => import("@/views/authority/NewEdit.vue"),
    props: true
  },
  // 設定（Stripe）
  {
    path: "/stripe",
    name: "stripe",
    component: () => import("@/views/stripe/Index.vue")
  },
  {
    path: "/stripe/connect",
    name: "stripe-connect",
    component: () => import("@/views/stripe/ConnectComplete.vue")
  },
  // 設定（認証コード）
  {
    path: "/auth-code",
    name: "auth-code",
    component: () => import("@/views/auth-code/Index.vue")
  },
  {
    path: "/auth-code/:id/edit",
    name: "auth-code-edit",
    component: () => import("@/views/auth-code/Edit.vue"),
    props: true
  },
  // レポート（オーダー&ペイ（次フェーズ見送り））
  // レポート（チケット受注管理）
  {
    path: "/ticket-order-management",
    name: "ticket-order-management",
    component: () => import("@/views/ticket-order-management/Index.vue")
  },
  {
    path: "/ticket-order-management/:id/list",
    name: "ticket-order-management-list",
    component: () => import("@/views/ticket-order-management/List.vue"),
    props: true
  },
  // レポート（チケット抽選管理）
  {
    path: "/ticket-lottery-management",
    name: "ticket-lottery-management",
    component: () => import("@/views/ticket-lottery-management/Index.vue")
  },
  {
    path: "/ticket-lottery-management/:id/list",
    name: "ticket-lottery-management-list",
    component: () => import("@/views/ticket-lottery-management/List.vue"),
    props: true
  },
  // LINEミニアプリ
  {
    path: "/line-miniapp",
    name: "line-miniapp",
    component: () => import("@/views/line-miniapp/Index.vue")
  },
  {
    path: "/line-miniapp/:shopId/edit",
    name: "line-miniapp-edit",
    component: () => import("../views/line-miniapp/NewEdit.vue"),
    props: true
  },
  // 会社概要管理
  {
    path: "/company-profile-management",
    name: "company-profile-management",
    component: () => import("@/views/corporate-management/Index.vue")
  },
  {
    path: "/company-profile-management/new",
    name: "company-profile-management-new",
    component: () => import("@/views/corporate-management/NewEdit.vue")
  },
  {
    //path: "/company-profile-management/:id/edit",
    path: "/company-profile-management/:shopId/edit",
    name: "company-profile-management-edit",
    component: () => import("@/views/corporate-management/NewEdit.vue"),
    props: true
  },
  // 求人管理
  {
    path: "/recruit-management",
    name: "recruit-management",
    component: () => import("@/views/recruit-management/Index.vue")
  },
  {
    path: "/recruit-management/new",
    name: "recruit-management-new",
    component: () => import("@/views/recruit-management/NewEdit.vue")
  },
  {
    //path: "/recruit-management/:id/edit",
    path: "/recruit-management/:shopId/edit",
    name: "recruit-management-edit",
    component: () => import("@/views/recruit-management/NewEdit.vue"),
    props: true
  },
  // お知らせ
  {
    path: "/information",
    name: "information",
    component: () => import("@/views/information/Index.vue")
  },
  // アプリダウンロードリダイレクト
  {
    path: "/app/download",
    name: "app-download",
    component: () => import("@/views/app/Download.vue")
  },
  {
    path: "*",
    redirect: "/home"
  },
  // USEN管理
  {
    path: "/usen-management",
    name: "usen-management",
    component: () => import("@/views/usen-manage/Index.vue")
  },
  {
    path: "/usen-management/new",
    name: "usen-manage-new",
    component: () => import("../views/usen-manage/NewEdit.vue")
  },
  {
    path: "/usen-management/:id/edit",
    name: "usen-manage-edit",
    component: () => import("../views/usen-manage/NewEdit.vue"),
    props: true
  },

  // liglly管理
  {
    path: "/liglly-management",
    name: "liglly-management",
    component: () => import("@/views/usen-manage/Index.vue")
  },
  {
    path: "/liglly-management/new",
    name: "liglly-manage-new",
    component: () => import("../views/usen-manage/NewEdit.vue")
  },
  {
    path: "/liglly-management/:id/edit",
    name: "liglly-manage-edit",
    component: () => import("../views/usen-manage/NewEdit.vue"),
    props: true
  },
  
  // 外部ポイント
  {
    path: "/external-points",
    name: "external-points",
    component: () => import("@/views/external-points/Index.vue")
  },
  {
    path: "/external-points/:shopId/list",
    name: "external-points-list",
    component: () => import("@/views/external-points/List.vue"),
    props: true
  },
];

const router = new VueRouter({
  mode: "history",
  routes
});

const BEFORE_LOGIN_ROUTE_NAMES: Array<string> = [
  "login",
  "forget-password",
  "forget-password-sendmail",
  "password-reset",
  "app-download"
];

router.beforeEach((to, from, next) => {
  const accessToken = LoginStorage.getAccessToken();
  if (accessToken) {
    if (BEFORE_LOGIN_ROUTE_NAMES.includes(to.name as string)) {
      if(to.name != "app-download"){ // アプリDL URLへのリダイレクトはログインしてても遷移させない
        next("/");
        return;
      }
    }
    Admin.menu().then();
  } else {
    if (!BEFORE_LOGIN_ROUTE_NAMES.includes(to.name as string)) {
      next("/login");
      return;
    }
  }
  next();
});

export default router;
